<!--
 * @Description: 城市、区域
 * @Author: 琢磨先生
 * @Date: 2022-05-09 10:56:44
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-05 11:39:30
-->

<template>
  <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus"
      >新增</el-button
    >
  </el-card>
  <el-card class="box data">
    <el-table
      v-loading="loading"
      :data="tableData"
      row-key="id"
      border
      default-expand-all
    >
      <el-table-column prop="name" label="名称" min-width="280" />
      <el-table-column prop="first_spell" label="首字母" width="100" />
      <el-table-column prop="adcode" label="区域编码" width="100" />
      <el-table-column prop="is_default" label="默认" width="100">
        <template #default="scope">
          {{ scope.row.is_default ? "默认" : "" }}
        </template>
      </el-table-column>
      <el-table-column label="状态" width="120">
        <template #default="scope">
          <el-tag type="warning" v-if="scope.row.status == 0">{{
            scope.row.status_text
          }}</el-tag>
          <el-tag type="success" v-if="scope.row.status == 1">{{
            scope.row.status_text
          }}</el-tag>
          <el-tag type="danger" v-if="scope.row.status == 2">{{
            scope.row.status_text
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="中心位置" width="100">
        <template #default="scope">
          {{ scope.row.lng ? "已设置" : "" }}
        </template>
      </el-table-column>
      <el-table-column prop="sequence" label="序号" width="100" />
      <el-table-column prop="is_search" label="可搜索" width="100">
        <template #default="scope">
          {{ scope.row.is_search ? "是" : "" }}
        </template>
      </el-table-column>
      <el-table-column prop="create_at" label="创建时间" width="180" />
      <el-table-column fixed="right" label="操作" width="150">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)"
            >修改</el-button
          >
          <el-popconfirm title="去定要删除？" @confirm="onDelete(scope.row)">
            <template #reference>
              <el-button type="danger" link size="small" class="text-danger"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <edit-city :item="current" :list="tableData" :reload="loadData"></edit-city>
</template>
<script>
import EditCity from "./edit_city.vue";
export default {
  components: {
    EditCity,
  },
  data() {
    return {
      loading: false,
      current: null,
      tableData: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      this.$http.get("/admin/v1/area").then((res) => {
        this.loading = false;
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
    },
    /**
     * 新增、修改
     */
    onEdit(item) {
      this.current = item
        ? item
        : {
            status: 1,
          };
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http.get("/admin/v1/area/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },
  },
};
</script>